import React from 'react';
import Navbar from '../Shared/Navbar';
import Footer from '../Shared/Footer';

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="container mt-5 mb-5">
        <h1 className="text-center mb-4">Privacy Policy</h1>

        <h2>Introduction</h2>
        <p>
          Welcome to Avinil IT Limited. We respect your privacy and are committed to protecting your personal data. This
          privacy policy explains how we handle your personal information when you visit our website, use our services,
          or interact with us.
        </p>

        <h2>Information We Collect</h2>
        <h3>1. Personal Information</h3>
        <p>
          We may collect personal information such as your name, email address, phone number, and any other details you
          provide when contacting us or using our services.
        </p>

        <h3>2. Usage Data</h3>
        <p>
          We collect information about how you interact with our website, such as your IP address, browser type, pages
          visited, and the time and date of your visit. This helps us understand user behavior and improve our services.
        </p>

        <h3>3. Cookies</h3>
        <p>
          Our website uses cookies to enhance your browsing experience. Cookies are small data files stored on your
          device that help us remember your preferences and provide a more personalized experience. You can control
          cookie settings through your browser.
        </p>

        <h2>How We Use Your Information</h2>
        <h3>1. Service Delivery</h3>
        <p>
          We use your personal information to provide and improve our services, respond to your inquiries, and fulfill
          our contractual obligations.
        </p>

        <h3>2. Communication</h3>
        <p>
          We may use your contact information to send you updates, newsletters, and promotional materials related to our
          services. You can opt-out of these communications at any time.
        </p>

        <h3>3. Analytics</h3>
        <p>
          We use usage data to analyze trends, administer our website, and gather demographic information. This helps us
          improve our website and tailor our services to better meet your needs.
        </p>

        <h3>4. Legal Compliance</h3>
        <p>
          We may process your personal information to comply with legal obligations, resolve disputes, and enforce our
          agreements.
        </p>

        <h2>Sharing Your Information</h2>
        <p>
          We do not sell, trade, or rent your personal information to third parties. However, we may share your
          information with:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> We may share your information with trusted third-party service providers
            who assist us in operating our website and delivering our services.
          </li>
          <li>
            <strong>Legal Authorities:</strong> We may disclose your information to legal authorities if required by law
            or in response to legal processes.
          </li>
        </ul>

        <h2>Data Security</h2>
        <p>
          We take data security seriously and implement appropriate measures to protect your personal information from
          unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the
          internet or electronic storage is completely secure.
        </p>

        <h2>Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>
            <strong>Access:</strong> Request a copy of the personal information we hold about you.
          </li>
          <li>
            <strong>Correction:</strong> Request corrections to any inaccurate or incomplete information.
          </li>
          <li>
            <strong>Deletion:</strong> Request the deletion of your personal information under certain circumstances.
          </li>
          <li>
            <strong>Objection:</strong> Object to the processing of your personal information for marketing purposes.
          </li>
          <li>
            <strong>Withdraw Consent:</strong> Withdraw your consent at any time where we rely on it to process your
            personal information.
          </li>
        </ul>
        <p>
          To exercise these rights, please contact us using the information provided below.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this privacy policy from time to time to reflect changes in our practices or legal obligations.
          We encourage you to review this policy periodically for any updates.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this privacy policy, please contact us at:
        </p>
        <address>
          <strong>Avinil IT Limited</strong> <br />
          Email: avinil.it.ltd@gmail.com <br />
          Phone: +8801822114359 <br />
          Location: Chattogram, Bangladesh
        </address>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
