import React from 'react';
import { Link } from 'react-router-dom';
import banner from '../../asset/Images/banner/carousel-2.jpg';

const Carousel = () => {
    return (
        <div>
            {/* <div className="container-fluid w-100 p-0 mb-5">
                <div className="owl-carousel w-100 header-carousel position-relative">
                    <div className="owl-carousel-item position-relative w-full">
                        <img className="img-fluid w-100" src={banner} alt=""/>
                            <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: 'rgba(24, 29, 56, .7)' }}>
                                <div className="container">
                                    <div className="row justify-content-start">
                                        <div className="col-sm-10 col-lg-8">
                                            <h1 className="display-3 text-white animated slideInDown" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="300">
                                                Your One-Stop Solution for <span className='text-danger'>Web Development</span> &amp; <span className='text-primary'>Digital Marketing</span>
                                            </h1>
                                            <h5 className="text-warning text-uppercase mb-3 animated slideInDown" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="300">
                                                Empowering Your Online Business Journey
                                            </h5>
                                            <p className="fs-5 text-white mb-4 pb-2" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="300">
                                                We offer a comprehensive suite of services to take your online business to the next level. From creating stunning websites to driving targeted traffic through digital marketing, our expertise ensures your success in the digital world.
                                            </p>
                                            <Link to="/contact" className="btn btn-danger py-md-3 px-md-5 me-3 animated slideInLeft" data-aos="fade-up" data-aos-duration="1000" data-aos-offset="300">
                                                Contact Us
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div> */}
            <div>
                <div className="container-fluid p-0 mb-5 ">
                    <div className="owl-carousel w-100 header-carousel position-relative">
                        <div className="owl-carousel-item position-relative w-100 ">
                            <img className="img-fluid w-100" src={banner} alt="Banner" />
                            <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center" style={{ background: 'rgba(24, 29, 56, .7)' }}>

                                <div className="container-fluid p-0 mb-5">
                                    <div className="row justify-content-center">
                                        <div className="col-11 col-md-10 col-lg-8">
                                            {/* Added responsive padding top for the content to avoid overlapping with the navbar */}
                                            <div data-aos="fade-up" className="d-flex flex-column justify-content-center justify-content-lg-start  min-vh-100" style={{ paddingTop: '60px' }}>
                                                <h1 className="display-4 pt- text-white animated slideInDown " data-aos-duration="1000" data-aos-offset="300">
                                                    Your One-Stop Solution for <span className='text-danger'>Web Development</span> &amp; <span className='text-primary'>Digital Marketing</span>
                                                </h1>
                                                <h5 className=" fs-6 fs-md-5 fs-lg-4 text-warning text-uppercase mb-3 " data-aos="" data-aos-duration="1000" data-aos-offset="300">
                                                    Empowering Your Online Business Journey
                                                </h5>
                                                <p className="fs-6 fs-md-5 fs-lg-4  text-white mb-4 pb-2" data-aos-duration="1000" data-aos-offset="300">
                                                    We offer a comprehensive suite of services to take your online business to the next level. From creating stunning websites to driving targeted traffic through digital marketing, our expertise ensures your success in the digital world.
                                                </p>
                                                <div className="d-flex  w-100">
                                                    <Link to="/contact" className="btn btn-danger py-2 px-4 px-md-5  animated slideInLeft text-center" data-aos-duration="1000" data-aos-offset="300" style={{ maxWidth: '100%', wordWrap: 'break-word' }}>
                                                        Contact Us
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Carousel;
