import React from 'react';

const AddTeamMember = () => {
    return (
        <div className=' card ms-5 mt-1 p-2 border-0'>
            hi team member
        </div>
    );
};

export default AddTeamMember;