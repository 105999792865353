import React from 'react';
import faq from '../../asset/Images/banner/faq2.jpg'
const Faq = () => {
    return (
        <div>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                        <div data-aos-duration="1000" data-aos-offset="300" data-aos="fade-right" className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                            <h1 className="mb-4 text-primary">Frequently <span className='text-warning'>Ask</span> Question <span className='text-danger fs-1'>?</span></h1>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            What services do you offer?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">We offer a range of services including custom software development, web development, IT solutions, and digital marketing. Each service is tailored to meet the unique needs of our clients.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                            How can I get started with a project?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Getting started is easy! Simply contact us through our website or give us a call. We'll set up an initial consultation to discuss your project requirements and how we can help.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                            What is your development process?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Our development process includes initial consultation, requirement analysis, design and prototyping, development, testing, and deployment. We ensure continuous communication and updates throughout the project.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                            Do you offer post-launch support?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">Yes, we provide comprehensive post-launch support to ensure your project runs smoothly. This includes maintenance, updates, and troubleshooting as needed.</div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                            What industries do you specialize in?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">We have experience working with a variety of industries, including healthcare, finance, education, retail, and more. Our team is adept at understanding the specific needs and challenges of different sectors.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div data-aos-duration="1000" data-aos-offset="300" data-aos="fade-left" className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="img-fluid position-absolute w-100 h-100" src={faq} alt="" style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Faq;
