import React from 'react';

const TermsCondition = () => {
    return (
        <div>
            hi term,s and condition
        </div>
    );
};

export default TermsCondition;