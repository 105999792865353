import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const OurClient = () => {
    return (
        <div>
            <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container ">
                    <div className="text-center">
                        <h6 className="bg-white text-center text-primary px-3">Clients</h6>
                        <h1 className="mb-5">Our <span className='text-danger'>Client</span> Say!</h1>
                    </div>

                    <>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={10}
                            autoplay={{
                                delay: 1500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 15,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                                },
                            }}
                            navigation={true}
                            modules={[Autoplay, Navigation]}
                            className="mySwiper"
                        >

                            {/* slider start here */}
                            <SwiperSlide>
                                <div className="testimonial-item text-center">
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <h5 className="mb-0">Rashed Khan</h5>
                                        <p className="mb-0">The service was excellent and the team was highly professional. Highly recommended!</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-item text-center">
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <h5 className="mb-0">Mina Rahman</h5>
                                        <p className="mb-0">Outstanding experience! The project was completed on time and exceeded our expectations.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-item text-center">
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <h5 className="mb-0">Tariq Ahmed</h5>
                                        <p className="mb-0">Professional and efficient service. The team went above and beyond to deliver exceptional results.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-item text-center">
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <h5 className="mb-0">Shahana Begum</h5>
                                        <p className="mb-0">Great experience! The quality of work and attention to detail were impressive.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-item text-center">
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <h5 className="mb-0">Hasan Mahmud</h5>
                                        <p className="mb-0">The team provided exceptional service and support throughout the project. Very satisfied!</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-item text-center">
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <h5 className="mb-0">Nadia Islam</h5>
                                        <p className="mb-0">Highly professional team with a great work ethic. The project was a success thanks to their expertise.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="testimonial-item text-center">
                                    <div className="testimonial-text bg-light text-center p-4">
                                        <h5 className="mb-0">Imran Hossain</h5>
                                        <p className="mb-0">Amazing service! They delivered beyond what we expected and were very responsive.</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </>
                </div>
            </div>
        </div>
    );
};

export default OurClient;
