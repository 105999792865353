import React from 'react';
import Navbar from '../Shared/Navbar';
import Footer from '../Shared/Footer';

const Services = () => {
  const servicesList = [
    {
      icon: 'fa-graduation-cap',
      title: 'Software Development',
      description: 'We provide custom software solutions tailored specifically to your business needs. Our focus is on delivering robust, scalable, and high-performance applications that enable you to achieve operational efficiency and long-term growth.',
    },
    {
      icon: 'fa-globe',
      title: 'Web Development',
      description: 'Our web development services create responsive, visually appealing, and user-friendly websites. We ensure that your online presence is both attractive and functional, helping you reach a wider audience and achieve your business goals.',
    },
    {
      icon: 'fa-home',
      title: 'IT Solutions',
      description: 'Our comprehensive IT solutions encompass network management, system integration, and technology optimization. We ensure your IT infrastructure is reliable, secure, and capable of supporting your business in a fast-changing environment.',
    },
    {
      icon: 'fa-book-open',
      title: 'Digital Marketing',
      description: 'We offer strategic digital marketing services that enhance your online visibility, attract more traffic, and increase conversion rates. From SEO to PPC, we provide solutions that drive business growth and foster customer engagement.',
    },
    {
      icon: 'fa-chart-line',
      title: 'SEO Optimization',
      description: 'Our SEO services focus on improving your website’s visibility on search engines, ensuring that your business reaches the top rankings for relevant keywords. This drives organic traffic and enhances your online presence.',
    },
    {
      icon: 'fa-bullhorn',
      title: 'Social Media Marketing',
      description: 'Our social media marketing services help you engage with your audience on popular platforms like Facebook, Instagram, and Twitter. We create targeted campaigns that boost brand awareness and drive customer engagement.',
    },
    {
      icon: 'fa-envelope-open',
      title: 'Email Marketing',
      description: 'Email marketing is one of the most effective ways to nurture leads and retain customers. Our email campaigns are tailored to your audience, delivering personalized content that drives conversions and enhances customer loyalty.',
    },
    {
      icon: 'fa-pencil-alt',
      title: 'Content Marketing',
      description: 'We create high-quality, relevant content that resonates with your audience. From blog posts to infographics, our content marketing strategies help build brand authority and attract potential customers to your business.',
    },
    {
      icon: 'fa-lightbulb',
      title: 'Startup Counseling',
      description: 'Our startup counseling services provide expert guidance and mentorship to budding entrepreneurs. We help you navigate the challenges of launching a new business, from idea validation to scaling your operations effectively.',
    },
  ];

  return (
    <div>
      <Navbar />

      <div className="text-center pt-5">
        <h1 className="mb-5">We Provide <span className="text-danger">Reliable</span> Services</h1>
      </div>

      <div className="container">
        <div className="row">
          {servicesList.map((service, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 mb-4"
            >
              <div className="service-item text-center p-3 border rounded shadow-sm" style={{ maxWidth: '350px', margin: '0 auto' }}>
                <i className={`fa fa-3x ${service.icon} text-danger mb-3`}></i>
                <h5 className="mb-3 text-success">{service.title}</h5>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Services;
